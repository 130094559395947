import { routerRoot } from '@admin/router';

const findRoute = (pathname = location.pathname, routes = routerRoot, depth = 0) => {
  let currentRoute = routes.find(route => route.fullPath === pathname || (depth === 0 && route.path === pathname));

  if (!currentRoute) {
      // 没有找到，往下级
      routes.some(route => {
          if (route.children && route.children.length) {
              currentRoute = findRoute(pathname, route.children, depth + 1);
          }
          return Boolean(currentRoute);
      });
  }
  return currentRoute;
};

export default findRoute;

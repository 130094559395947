import { useEffect, Suspense, useMemo } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { routerRoot } from '@admin/router';
import Loading from '@admin/components/common/Loading';
import store from 'store';
import { ConfigProvider } from 'antd';
import { useAppStore } from './hox/app';
import findRoute from '@utils/find-route.js';
import { importLanguagePackage } from '@utils/i18n';
import { validToken } from './api/user';
import { heartBeat, userCheckSteps } from '@api/login';
import { useMemoizedFn } from 'ahooks';
import { useUserBaseInfo } from '@utils/useBaseUserInfo';
import { filterRouterByUserInfo } from '@utils';
import { webSiteColors } from '@utils/customize';
import { translation, prohibitScaling } from '@admin/utils/tools/base';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { i18next } from '@utils/languages/i18n';

// 不需要权限的页面
const noLogin = routerRoot.filter(item => !item.auth).map(item => item.path);
const languageEnum = ['ZH_CN', 'EN_US', ' ES_ES', 'PL_PL', 'PT_PT', 'DE_DE', 'IT_IT', 'CS_CZ'];
// 初始化语言包
// initLanguage();

function App() {
    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate();
    const token = store.get('token');
    const { locale, setLocale } = useAppStore();

    const { t, i18n } = useTranslation();

    // 当用用户信息
    const { baseUserInfo: userBaseInfo } = useUserBaseInfo();

    // 是否登录
    const hasToken = useMemo(() => Boolean(token), [token]);

    const handleCheckToken = useMemoizedFn(async () => {
        try {
            await validToken(token);
            // const { body: customSettingList } = await queryCustomSetting()
            // const generationSetting = customSettingList.find(item => item.businessId === GENERATION_SETTING_CODE);
            // if (generationSetting && generationSetting.businessBodys) {
            //     store.set('locale', languageEnum[generationSetting.businessBodys.language?.at(-1) - 1]);
            // }
            userCheckSteps().then(({ successful, body }) => {
                if (successful) {
                    if (body.systemSettings == false && body.password == false) {
                        // 代建第一步
                        // 跳转系统引导页
                        navigate('/systemBoot', { state: { step: 1, isOwner: false } });
                    } else if (body.systemSettings == true && body.password == false) {
                        // 代建第二步
                        // 跳转系统引导页
                        navigate('/systemBoot', { state: { step: 2, isOwner: false } });
                    } else if (body.systemSettings == false && body.password == true) {
                        // 自建第一步
                        // 跳转系统引导页
                        navigate('/systemBoot', { state: { step: 1, isOwner: true } });
                    }
                }
            });
        } catch {
            store.remove('token');
            navigate('/login');
        }
    });

    useEffect(() => {
        importLanguagePackage(store.get('locale')).then(antdLanguagePackage => {
            setLocale(antdLanguagePackage);
        });
    }, [store.get('locale')]);

    useEffect(() => {
        heartBeat();
    }, [pathname]);

    useEffect(() => {
        if (pathname === '/' || (!noLogin.includes(pathname) && !hasToken)) {
            navigate('/login');
        } else {
            const currentRoute = findRoute(pathname);

            if (currentRoute) {
                // 设置标题
                // document.title = currentRoute.name;
                document.title = translation(currentRoute.title || '');
            }
        }
    }, [pathname, i18n.language, t]);

    useEffect(() => {
        if (hasToken) handleCheckToken();
    }, [token]);

    useEffect(() => {
        // prohibitScaling();
    }, []);

    /**
     * 动态渲染节点
     * @param routes
     * @type function(*=): *
     * @returns {*}
     */
    const renderRoutes = useMemoizedFn((routes, parentRoutes = [], depth = 0) => {
        const routeList = routes.map((route, i) => {
            // 挂载附加信息
            const routeQueue = depth > 0 ? [...parentRoutes, route] : [...parentRoutes];

            Object.assign(route, {
                routeQueue,
                fullPath: `/${routeQueue.map(({ path }) => path).join('/')}`
            });

            // 继承 showIn 与 showInRoles
            if (depth > 1) {
                const parentRoute = parentRoutes[depth - 2];

                // 管理模块中特殊处理 只有业主有消息中心
                if (parentRoute?.path === 'manager') {
                    if (route.hasOwnProperty('redirect') && route.path === '') {
                        if (userBaseInfo?.userType === 'OWNER') {
                            route.redirect = 'notice';
                        } else {
                            route.redirect = 'list';
                        }
                    }
                }

                if (!route.showIn && parentRoute.showIn) {
                    Object.assign(route, {
                        showIn: parentRoute.showIn
                    });
                }
                if (!route.showInRoles && parentRoute.showInRoles) {
                    Object.assign(route, {
                        showInRoles: parentRoute.showInRoles
                    });
                }
            }

            let element = null;

            if (route.redirect) {
                element = <Navigate to={route.redirect} />;
            } else if (route.component) {
                element = (
                    <Suspense fallback={null}>
                        <route.component route={route} />
                    </Suspense>
                );
            }

            return (
                <Route key={i} path={route.path} element={element}>
                    {route.children && renderRoutes(route.children, routeQueue, depth + 1)}
                </Route>
            );
        });

        if (!userBaseInfo) return routeList;
        return filterRouterByUserInfo(routeList, userBaseInfo);
    });

    const Router = useMemo(() => renderRoutes(routerRoot), [routerRoot, userBaseInfo]);

    if (!userBaseInfo && !noLogin.includes(pathname)) {
        return <Loading />;
    }

    return (
        <Suspense fallback={<Loading />}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: webSiteColors()
                    }
                }}
                locale={locale}>
                <I18nextProvider i18n={i18next}>
                    <Routes>{Router}</Routes>
                </I18nextProvider>
            </ConfigProvider>
        </Suspense>
    );
}

export default App;

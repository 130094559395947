import { createGlobalStore } from 'hox';
import { useState } from 'react';

/**
 * 第一个元素是用来订阅 Hook 函数
 * 第二个元素是一个静态获取函数  可以在任何地方使用(js/ts/jsx/tsx)
 * TODO: 使用方法  const {themeParams} = getAppStore();
 */
export const [useAppStore, getAppStore] = createGlobalStore(() => {
    // 子菜单
    const [showSubMenu, setShowSubMenu] = useState(true);

    // 刷新
    const [update, setUpdate] = useState(0);
    const force = () => setUpdate(update + 1);

    // 本地语言
    const [locale, setLocale] = useState();

    return {
        showSubMenu,
        setShowSubMenu,
        force,
        locale,
        setLocale
    };
});

import ReactDOM from 'react-dom/client';
import App from './App';
import Loading from './components/common/Loading';
import '@admin/global/global.less';
import '@admin/global/var.less';
import 'normalize.css';
import { BrowserRouter } from 'react-router-dom';
import { HoxRoot } from 'hox';
import storeRedux from './store/index.js';
import { Provider } from 'react-redux';
import { languageList } from '@utils/languages/i18n';
import { settingFavicon } from '@utils/customize';
import { heartBeat } from '@admin/api/login';
import { i18nList } from '@admin/utils/languages/country-languages';

const rootDom = ReactDOM.createRoot(document.getElementById('root'));

(function () {
    // 设置odm配置
    settingFavicon().then(async () => {
        await i18nList();

        await heartBeat();

        await rootDom.render(<Loading />);

        await languageList();

        rootDom.render(
            <HoxRoot>
                <Provider store={storeRedux}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </Provider>
            </HoxRoot>
        );
    });
})();

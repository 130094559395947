import { lazy } from 'react';

// icon
import UserCenterIcon from '@assets/svg/user-center.svg';
import DeviceIcon from '@assets/svg/device.svg';
import DeviceActiveIcon from '@assets/svg/device-active.svg';
// import DashboardIcon from '@assets/svg/dashboard.svg';
// import DashboardActiveIcon from '@assets/svg/dashboard-active.svg';
import ManagerIcon from '@assets/svg/manager.svg';
import ManagerActiveIcon from '@assets/svg/manager-active.svg';
import PowerStationIcon from '@assets/svg/power-station.svg';
import PowerStationActiveIcon from '@assets/svg/power-station-active.svg';
import WarningIcon from '@assets/svg/warning.svg';
import WarningActiveIcon from '@assets/svg/warning-active.svg';
import ReportIcon from '@assets/svg/report.svg';
import ReportActiveIcon from '@assets/svg/active-report.svg';

// import store from 'store';
// import { getUseUserBaseInfo } from '@admin/utils/useBaseUserInfo';

// const { baseUserInfo } = getUseUserBaseInfo();

/**
 * 路由文件
 */

export const routerRoot = [
    {
        name: '登录',
        title: 'login.login',
        path: '/login',
        auth: false,
        component: lazy(() => import('@admin/views/login'))
    },
    {
        name: '忘记密码',
        path: '/forgot',
        auth: false,
        title: 'login.forgetPwd',
        component: lazy(() => import('@admin/views/forgot'))
    },
    {
        name: '注册',
        title: 'login.registerText',
        path: '/register',
        auth: false,
        component: lazy(() => import('@admin/views/register'))
    },
    {
        name: '隐私政策',
        title: 'setting.privacyPolicy',
        path: '/policyAgree',
        auth: false,
        component: lazy(() => import('@admin/views/policyAgree'))
    },
    {
        name: '系统引导',
        title: 'systemBoot',
        path: '/systemBoot',
        auth: false,
        component: lazy(() => import('@admin/views/systemBoot'))
    },
    {
        path: '*',
        auth: true,
        component: lazy(() => import('../Error'))
    },
    {
        path: '',
        auth: true,
        name: 'menu',
        component: lazy(() => import('@admin/components/Layout')),
        children: [
            {
                path: 'power-station',
                auth: true,
                name: '电站',
                title: 'station.title',
                icon: PowerStationIcon,
                activeIcon: PowerStationActiveIcon,
                showIn: ['OWNER', 'DISTRIBUTOR', 'PLANT'],
                children: [
                    {
                        path: '',
                        redirect: 'list'
                    },
                    {
                        path: 'list',
                        name: '电站列表',
                        title: 'station.stationList',
                        isParent: true,
                        component: lazy(() => import('@admin/views/power-station/list'))
                    },
                    {
                        path: 'detail',
                        name: '电站详情',
                        title: 'station.detail',
                        hideInMenu: true,
                        children: [
                            {
                                path: '',
                                component: lazy(() => import('@admin/views/power-station/detail'))
                            },
                            {
                                path: 'device',
                                name: '设备详情',
                                title: 'device.deviceDetail',
                                component: lazy(() => import('@admin/views/power-station/device'))
                            },
                            {
                                path: 'chargingDetail',
                                name: '充电桩详情',
                                title: 'device.deviceDetail',
                                component: lazy(() => import('@admin/views/power-station/chargingDetail'))
                            }
                        ]
                    }
                ]
            },
            {
                path: 'device',
                auth: true,
                name: '设备',
                title: 'device.device',
                icon: DeviceIcon,
                activeIcon: DeviceActiveIcon,
                showIn: ['DISTRIBUTOR', 'PLANT', 'OWNER'],
                children: [
                    {
                        path: '',
                        redirect: 'list'
                    },
                    {
                        path: 'list',
                        name: '设备列表',
                        title: 'login.deviceList',
                        component: lazy(() => import('@admin/views/device/list'))
                    },
                    {
                        path: 'firmware-update',
                        name: '固件升级',
                        title: 'device.upgrade',
                        showInRoles: ['SUPER_ADMIN', 'ADMIN', 'ORDINARY', 'VISITOR', 'DEMO'],
                        component: lazy(() => import('@admin/views/device/firmware-update'))
                    }
                ]
            },
            {
                path: 'warning-manager',
                auth: true,
                name: '告警',
                title: 'warn.title',
                icon: WarningIcon,
                activeIcon: WarningActiveIcon,
                showIn: ['DISTRIBUTOR', 'PLANT'],
                component: lazy(() => import('@admin/views/warning-manager'))
            },
            {
                path: 'report',
                auth: true,
                name: '报表',
                title: 'report.text',
                icon: ReportIcon,
                activeIcon: ReportActiveIcon,
                showIn: ['DISTRIBUTOR', 'PLANT', 'OWNER'],
                children: [
                    {
                        path: '',
                        redirect: 'power-station'
                    },
                    {
                        path: 'power-station',
                        name: '电站报表',
                        title: 'power.station.statements',
                        component: lazy(() => import('@admin/views/report/power-station-statements'))
                    },
                    {
                        path: 'export-records',
                        name: '导出记录',
                        title: 'export.records',
                        showInRoles: ['SUPER_ADMIN', 'ADMIN', 'ORDINARY', 'DEMO'],
                        component: lazy(() => import('@admin/views/report/exporting-records'))
                    }
                ]
            },
            {
                path: 'manager',
                auth: true,
                name: '管理',
                title: 'manage.title',
                icon: ManagerIcon,
                activeIcon: ManagerActiveIcon,
                children: [
                    {
                        path: '',
                        showIn: ['DISTRIBUTOR', 'PLANT'],
                        showInRoles: ['SUPER_ADMIN', 'ADMIN', 'ORDINARY', 'VISITOR', 'DEMO'],
                        redirect: 'list'
                    },
                    {
                        path: 'list',
                        name: '组织管理',
                        showIn: ['DISTRIBUTOR', 'PLANT'],
                        showInRoles: ['SUPER_ADMIN', 'ADMIN', 'ORDINARY', 'VISITOR', 'DEMO'],
                        title: 'setting.orgManager',
                        component: lazy(() => import('@admin/views/manager/list'))
                    },
                    {
                        path: 'owner',
                        showInRoles: ['SUPER_ADMIN', 'ADMIN'],
                        showIn: ['DISTRIBUTOR', 'PLANT'],
                        name: '业主管理',
                        title: 'manage.owner',
                        component: lazy(() => import('@admin/views/manager/owner'))
                    },
                    {
                        path: 'log',
                        showInRoles: ['SUPER_ADMIN', 'ADMIN'],
                        showIn: ['PLANT'],
                        name: '操作记录',
                        title: 'manage.operation',
                        component: lazy(() => import('@admin/views/manager/log'))
                    },
                    {
                        path: 'feeback',
                        name: '意见管理',
                        title: 'manage.feedback',
                        showInRoles: ['SUPER_ADMIN', 'ADMIN'],
                        showIn: ['PLANT'],
                        component: lazy(() => import('@admin/views/manager/feedback'))
                    },
                    {
                        path: 'message',
                        name: '公告管理',
                        title: 'manage.announce',
                        showInRoles: ['SUPER_ADMIN', 'ADMIN'],
                        showIn: ['PLANT'],
                        component: lazy(() => import('@admin/views/manager/sys-notice'))
                    },
                    {
                        path: 'notice',
                        name: '消息中心',
                        title: 'manage.notice',
                        component: lazy(() => import('@admin/views/manager/notice-center'))
                    },
                    {
                        path: 'language-list',
                        name: '多语言',
                        title: 'multilingual.title',
                        showInRoles: ['SUPER_ADMIN', 'ADMIN'],
                        showIn: ['PLANT'],
                        component: lazy(() => import('@admin/views/language-list'))
                    }
                ]
            },
            {
                path: 'user-center',
                auth: true,
                name: '账户',
                title: 'account.title',
                icon: UserCenterIcon,
                activeIcon: UserCenterIcon,
                isUserCenter: true,
                showInRoles: ['SUPER_ADMIN', 'ADMIN', 'ORDINARY', 'VISITOR', 'OWNER'],
                children: [
                    {
                        path: '',
                        redirect: 'account-setting'
                    },
                    {
                        path: 'account-setting',
                        name: '账户设置',
                        title: 'setting.accountSetting',
                        component: lazy(() => import('@admin/views/user-center/account-setting'))
                    },
                    {
                        path: 'generation-setting',
                        name: '通用设置',
                        title: 'account.common',
                        component: lazy(() => import('@admin/views/user-center/generation-setting'))
                    },
                    {
                        path: 'privacy-clause',
                        name: '隐私条款',
                        title: 'account.privay',
                        component: lazy(() => import('@admin/views/user-center/privacy-clause'))
                    },
                    {
                        path: 'feedback',
                        name: '意见反馈',
                        title: 'setting.feedback',
                        component: lazy(() => import('@admin/views/user-center/feedback'))
                    }
                ]
            }
        ]
    }
];

import { getLanguageList } from '@api/i18n';
import { API_TIMEOUT } from './constant';

// 将标准语言包 code 转换成项目后面的语言包 code
export const parseLanguageCode = standardCode => standardCode.replace(/\-/g, '_').toUpperCase();

// 将项目的语言包 code 转换成 mapbox 的语言包
export const transToMapboxLanguageCode = languageCode => {
    const [preCode] = languageCode.toLowerCase().split(/_|\-/);

    if (preCode === 'zh') {
        // 中文需要特殊处理
        return 'zh-Hans';
    }

    return languageCode;
};

// 返回支持的语言列表
export const languageList = async () => {
    try {
        const { body } = await getLanguageList.memo();

        return body.map(({ k, v }) => ({
            text: k,
            key: v
        }));
    } catch {
        getLanguageList.clearMemo();
    }
};

/**
 * 动态加载语言包
 */
const languagePackagePromiseSet = {};

export const importLanguagePackage = async languageCode => {
    let promise = languagePackagePromiseSet[languageCode];

    if (!promise) {
        let promiseResolve;
        let promiseReject;

        // 下面的写法是为了绕过 eslint
        promise = new Promise((resolve, reject) => {
            promiseResolve = resolve;
            promiseReject = reject;
        });

        if (languageCode) {
            setTimeout(() => {
                promiseReject(new Error('语言包加载超时'));
            }, API_TIMEOUT);
        }

        (async () => {
            switch (languageCode) {
                case 'CS_CZ':
                    const [cs] = await Promise.all([import('antd/locale/cs_CZ'), import('dayjs/locale/cs')]);

                    promiseResolve(cs.default);
                    break;
                case 'DE_DE':
                    const [de] = await Promise.all([import('antd/locale/de_DE'), import('dayjs/locale/de')]);

                    promiseResolve(de.default);
                    break;
                case 'EN_US':
                    const [en] = await Promise.all([import('antd/locale/en_US'), import('dayjs/locale/en')]);

                    promiseResolve(en.default);
                    break;
                case 'ES_ES':
                    const [es] = await Promise.all([import('antd/locale/es_ES'), import('dayjs/locale/es')]);

                    promiseResolve(es.default);
                    break;
                case 'HR_HR':
                    const [hr] = await Promise.all([import('antd/locale/hr_HR'), import('dayjs/locale/hr')]);

                    promiseResolve(hr.default);
                    break;
                case 'IT_IT':
                    const [it] = await Promise.all([import('antd/locale/it_IT'), import('dayjs/locale/it')]);

                    promiseResolve(it.default);
                    break;
                case 'PL_PL':
                    const [pl] = await Promise.all([import('antd/locale/pl_PL'), import('dayjs/locale/pl')]);

                    promiseResolve(pl.default);
                    break;
                case 'PT_PT':
                    const [pt] = await Promise.all([import('antd/locale/pt_PT'), import('dayjs/locale/pt')]);

                    promiseResolve(pt.default);
                    break;
                case 'VI_VN':
                    const [vi] = await Promise.all([import('antd/locale/vi_VN'), import('dayjs/locale/vi')]);

                    promiseResolve(vi.default);
                    break;
                case 'ZH_CN':
                    const [zh] = await Promise.all([import('antd/locale/zh_CN'), import('dayjs/locale/zh')]);

                    promiseResolve(zh.default);
                    break;
                default:
            }
        })();

        languagePackagePromiseSet[languageCode] = promise;
    }
    try {
        const languagePackage = await promise;

        return languagePackage;
    } catch (err) {
        delete languagePackagePromiseSet[languageCode];
        console.error('语言包下载失败', err);
    }
};

import React from 'react';
import './style.less';

class Loading extends React.Component {
    componentDidMount() {
        // console.log('组件：loading...');
    }
    render() {
        return (
            <div className="loading_example">
                {/* <span className="loader" style={styleObj}> */}
                {/*    {loadingText()} */}
                {/* </span> */}

                <span className="loader"></span>
            </div>
        );
    }
}

export default Loading;
